export default [
  {
    title: 'sidebar.home',
    route: 'home',
    icon: 'HomeIcon',
    role: 'user',
  },
  {
    header: 'sidebar.account',
    role: 'user',
  },
  {
    title: 'sidebar.profile',
    route: 'pages-user-setting',
    icon: 'AtSignIcon',
    role: 'user',
  },
  {
    title: 'sidebar.areaTypeLimits',
    route: 'pages-area-type-limit',
    icon: 'CastIcon',
    role: 'user',
  },
  {
    title: 'sidebar.barriers',
    route: 'pages-barrier',
    icon: 'AnchorIcon',
    role: 'user',
  },
  {
    title: 'sidebar.reducingDeciLayers',
    route: 'pages-reducing-deci-layer',
    icon: 'LayersIcon',
    role: 'user',
  },
  {
    title: 'sidebar.assets',
    route: 'pages-asset',
    icon: 'RssIcon',
    role: 'user',
  },
  {
    title: 'sidebar.calcParams',
    route: 'pages-calc-param',
    icon: 'ActivityIcon',
    role: 'user',
  },
  {
    title: 'sidebar.projects',
    route: 'pages-project',
    icon: 'BookOpenIcon',
    role: 'user',
  },
  {
    title: 'sidebar.users',
    route: 'pages-user',
    icon: 'UsersIcon',
    role: 'admin',
  },
  {
    title: 'sidebar.busines',
    route: 'busines-plan',
    icon: 'UploadIcon',
    role: 'user',
  },
]
