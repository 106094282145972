<template>
  <b-navbar-nav class="nav">
    <b-nav-item
      v-for="(bookmark, index) in bookmarkedItems"
      :id="`bookmark-${index}`"
      :key="index"
      :to="bookmark.route"
    >
      <feather-icon
        :icon="bookmark.icon"
        size="21"
      />
      <b-tooltip
        triggers="hover"
        :target="`bookmark-${index}`"
        :title="bookmark.title"
        :delay="{ show: 1000, hide: 50 }"
      />
    </b-nav-item>
    <b-nav-item-dropdown
      link-classes="bookmark-star"
      lazy
      @hidden="resetsearchQuery"
    >
      <feather-icon
        slot="button-content"
        icon="StarIcon"
        size="21"
        class="text-warning"
      />

      <!-- Dropdown Content -->
      <li style="min-width:300px">
        <div class="p-1">
          <b-form-input
            id="boomark-search-input"
            v-model="searchQuery"
            :placeholder="$t('navbar.explore')"
            autofocus
          />
        </div>
        <vue-perfect-scrollbar
          :settings="{ maxScrollbarLength: 60 }"
          class="search-list search-list-bookmark scroll-area"
          :class="{'show': filteredData && filteredData.length }"
          tagname="ul"
        >

          <b-dropdown-item
            v-for="(suggestion, index) in filteredData"
            :key="index"
            class="suggestion-group-suggestion cursor-pointer"
            link-class="d-flex align-items-center"
            :to="suggestion.route"
            @mouseenter="currentSelected = index"
          >
            <feather-icon
              :icon="suggestion.icon"
              class="mr-75"
              size="18"
            />
            <span class="align-middle">{{ suggestion.title }}</span>
            <feather-icon
              icon="StarIcon"
              class="ml-auto"
              size="16"
              :class="{'text-warning': suggestion.isBookmarked}"
              @click.stop.prevent="toggleBookmarked(suggestion)"
            />

          </b-dropdown-item>
          <b-dropdown-item
            v-show="!(filteredData.length) && searchQuery"
            disabled
          >
            {{ $t('navbar.noResults') }}
          </b-dropdown-item></vue-perfect-scrollbar>
      </li>
    </b-nav-item-dropdown>

  </b-navbar-nav>
</template>

<script>
import {
  BNavbarNav, BNavItem, BTooltip, BNavItemDropdown, BFormInput, BDropdownItem,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import searchAndBookmarkRawData from '@/navigation/vertical'

export default {
  components: {
    BNavbarNav, BNavItem, BTooltip, BNavItemDropdown, BFormInput, VuePerfectScrollbar, BDropdownItem,
  },

  data() {
    return {
      currentSelected: -1,
      searchQuery: '',
      bookmarks: [],
    }
  },

  computed: {
    searchAndBookmarkDataPages() {
      return searchAndBookmarkRawData
        .filter(item => !item.header)
        .map(item => ({
          key: item.title,
          title: this.$t(item.title),
          route: { name: item.route },
          icon: item.icon,
          isBookmarked: this.isBookmarked(item.title),
        }))
    },

    bookmarkedItems() {
      return this.searchAndBookmarkDataPages.filter(item => item.isBookmarked)
    },

    filteredData() {
      const query = this.searchQuery.toLowerCase()
      return this.searchAndBookmarkDataPages.filter(item => item.title.toLowerCase().includes(query)).slice(0, 6)
    },
  },

  created() {
    this.bookmarks = this.recoverBookmarks()
  },

  methods: {
    resetsearchQuery() { this.searchQuery = '' },

    toggleBookmarked(item) {
      if (this.isBookmarked(item.key)) {
        this.bookmarks = this.bookmarks.filter(b => b !== item.key)
      } else {
        this.bookmarks.push(item.key)
      }
      this.saveBookmarks()
    },

    isBookmarked(key) {
      return this.bookmarks.includes(key)
    },

    saveBookmarks() {
      localStorage.setItem('navbar-bookmarks', JSON.stringify(this.bookmarks))
    },

    recoverBookmarks() {
      let result = []
      try {
        const parsed = JSON.parse(localStorage.getItem('navbar-bookmarks'))
        if (!Array.isArray(parsed)) { throw new Error('Invalid') }
        result = parsed
      } catch (err) {
        localStorage.removeItem('navbar-bookmarks')
      }
      return result
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

.nav-bookmar-content-overlay {
    position: fixed;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.7s;
    transition: all 0.7s;
    z-index: -1;

    &:not(.show) {
      pointer-events: none;
    }

    &.show {
      cursor: pointer;
      z-index: 10;
      opacity: 1;
    }
}
</style>
